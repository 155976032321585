<template>
    <div id="pageList" class="customerAccountList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">运单号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.code = validForbid(e)" placeholder="请输入运单号进行模糊匹配查询..." v-model="pageList.queryParam.code" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">车次号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.carOrder = validForbid(e)" placeholder="请输入车次号进行模糊匹配查询..." v-model="pageList.queryParam.carOrder" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">车牌号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.carId = validForbid(e)" placeholder="请输入车牌号进行模糊匹配查询..." v-model="pageList.queryParam.carId" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">司机：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.driverId = validForbid(e)" placeholder="请输入司机进行模糊匹配查询..." v-model="pageList.queryParam.driverId" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">客户：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.customerId = validForbid(e)" placeholder="请输入客户进行模糊匹配查询..." v-model="pageList.queryParam.customerId" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">派车时间：</el-col>
                    <el-col :span="9">
                        <el-date-picker v-model="pageList.queryParam.time" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">要求装货时间：</el-col>
                    <el-col :span="9">
                        <el-date-picker v-model="pageList.queryParam.loadTime" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">要求送货时间：</el-col>
                    <el-col :span="9">
                        <el-date-picker v-model="pageList.queryParam.deliveryTime" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">是否对账：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('customerId',el)" v-model="pageList.queryParam.accountFlag" placeholder="请选择对账状态" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'accountFlag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
            </template>
            <!--重写表格固定列-->
            <template v-slot:fixCol>
                <el-table-column type="selection" width="55" />
                <el-table-column fixed="left" header-align="center" align="center" label="操作" width="180">
                    <template #default="scope">
                        <el-button @click="pageListRef.editHandler(scope.row)" type="text">
                            <i class="el-icon-edit"></i>&nbsp;&nbsp;车次详情
                        </el-button>
                        <el-button @click="singleSureAccount(scope.row)" type="text" v-if="scope.row['F_CUSTOMER_STATUS']==0">
                            <i class="el-icon-edit"></i>&nbsp;&nbsp;确认对账
                        </el-button>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_CUSTOMER_STATUS_NAME" label="对账状态"/>
                <el-table-column prop="F_CUSTOMER_TIME" label="对账时间"/>
                <el-table-column prop="F_CAR_ORDER" label="车次号"/>
                <el-table-column prop="F_TIME" label="派车时间"/>
                <el-table-column prop="F_LOAD_TIME" label="要求装货时间"/>
                <el-table-column prop="F_DELIVERY_TIME" label="要求送货时间"/>
                <el-table-column prop="F_SALE_BILL_NAME" label="项目"/>
                <el-table-column prop="F_WAY_BILL_CODE" label="运单">
                    <template #default="scope">
                        <el-tag style="cursor: pointer" @click="viewWayBill(scope.row)">{{ scope.row.F_WAY_BILL_CODE }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="F_AUTH_CODE" label="委托单号"/>
                <el-table-column prop="F_CAR_ID" label="车辆"/>
                <el-table-column prop="F_DRIVER_ID" label="司机"/>
                <el-table-column prop="F_CUSTOMER_ID" label="客户"/>
                <el-table-column label="客户运费(元)">
                    <el-table-column prop="F_BEGAN" label="起步价"/>
                    <el-table-column prop="F_MILEAGE" label="超里程费用"/>
                    <el-table-column prop="F_POINT_NUM" label="超点位费用"/>
                    <el-table-column prop="F_NUM" label="超件数费用"/>
                    <el-table-column prop="F_WEIGHT" label="超重量费用"/>
                    <el-table-column prop="F_SIZE" label="超体积费用"/>
                    <el-table-column prop="F_DISCOUNT" label="折扣"/>
                    <el-table-column prop="F_TOTAL" label="客户运费合计">
                        <template #default="scope">
                            <span style="color: #e74c3c">{{ scope.row.F_TOTAL }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="扣款及补贴(元)">
                    <el-table-column prop="F_BT" label="补贴金额"/>
                    <el-table-column prop="F_FWFK" label="服务罚款"/>
                    <el-table-column prop="F_SPKK" label="货损扣款"/>
                    <el-table-column prop="F_WZPK" label="物资赔款"/>
                    <el-table-column prop="F_JSJJ" label="结算金额">
                        <template #default="scope">
                            <span style="color:#c0392b">{{ scope.row.F_JSJJ }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import AccountCard from '../common/AccountCard';
    import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, provide, computed} from 'vue';
    import WayBillCard from "../../dispatch/wayBill/WayBillCard";
    export default defineComponent ({
        name: "customerAccountList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                personData:[],//异常登记人员下拉
                pageList: {
                    queryParam: {
                        quotationType:'customer',
                        code:"",
                        carOrder:"",
                        carId:"",
                        driverId:"",
                        customerId:"",
                        time:"",
                        loadTime:'',
                        deliveryTime:'',
                        accountFlag:''
                    },
                    modelComp: AccountCard,
                    modelMethod: "/customerAccount/pageData"
                }
            })

            onMounted(async ()=>{
            })

            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'accountFlag'==params.comboId){
                        return [{value:'0',label:'未对账'},{value:'1',label:'已对账'}];
                    }
                }
            })
            //查看运单
            const viewWayBill=async (row)=>{
                await utils.$$lghdUtils.buildCommonDialog({from:'CustomerAccountList',id:row['F_WAY_BILL_ID'],title: "运单",dialogDiv:"WayBillCard",
                    modelComp: WayBillCard,proxy:proxy});
            }
            const doAccount=async (id)=>{
                utils.$$tools.configBox({
                    msgContent:'确认执行该操作吗?',
                    fn:async ()=> {
                        let res = await utils.$$api.postRequest({ url:'/customerAccount/custom', params: {id:id,operateType:'sureAccount',quotationType:dataObj.pageList.queryParam.quotationType}});
                        if(res.result){
                            utils.$$tools.success({ message: res.msg });
                            dataObj.pageListRef.queryHandler();
                        }
                    }
                });
            }
            //批量确认对账
            const sureAccountHandler=async ()=>{
                let ids='';
                let selections=dataObj.pageListRef.getTbInst().getSelection();
                if(selections.length==0){
                    proxy.$message.info("请选择要确认对账的行");
                    return false;
                }
                let flag=false;
                selections.forEach(item=>{
                    if(item['F_CUSTOMER_STATUS']==0)ids=ids+item['F_ID']+',';
                    else flag=true;
                })
                if(flag){
                    proxy.$message.info("请全部选择未确认对账的行");
                    return false;
                }
                ids=ids.substr(0,ids.length-1);
                await doAccount(ids);
            }
            //逐条确认对账
            const singleSureAccount=async (row)=>{
                if(row['F_CUSTOMER_STATUS']==0)await doAccount(row['F_ID']);
            }
            //导出
            const exportExcel=(fields,labels, data,listEngineInst)=>{
                let fistHeader=['对账状态','对账时间','车次号','派车时间','要求装货时间','要求送货时间','项目','运单','委托单号','车辆','司机','客户','客户运费（元）','','','','','','','','扣款及补贴（元）','','','',''];
                let sendHeader=['','','','','','','','','','','','','起步价','超里程费用','超点位费用','超件数费用','超重量费用','超体积费用','折扣','客户运费合计','补贴金额','服务罚款','货损扣款','物资赔款','结算金额'];
                let merges = ["A1:A2", "B1:B2", "C1:C2", "D1:D2", "E1:E2", "F1:F2", "G1:G2", "H1:H2", "I1:I2", "J1:J2", "K1:K2", "L1:L2", "M1:T1", "U1:Y1"]; // 根据Excel确定要合并的单元格
                proxy.exportMulHeadExcel({
                    fistHeader, // 这里是第一行的表头
                    sendHeader, //这里是第二行的表头

                    // header: tHeader, //这里应该是算第三行的表头
                    data,
                    merges,
                    filename: '客户对账单电子表格',
                });
            }
            return{
                ...toRefs(dataObj),comboSelect,viewWayBill,sureAccountHandler,singleSureAccount,exportExcel
            }
        }
    });
</script>

<style scoped>
    .customerAccountList{
        width: 100%;
    }
    .aa{
        color: red;
    }
</style>